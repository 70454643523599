// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-alumni-js": () => import("./../../../src/pages/alumni.js" /* webpackChunkName: "component---src-pages-alumni-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-esports-js": () => import("./../../../src/pages/esports.js" /* webpackChunkName: "component---src-pages-esports-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-innovation-js": () => import("./../../../src/pages/innovation.js" /* webpackChunkName: "component---src-pages-innovation-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-oer-js": () => import("./../../../src/pages/oer.js" /* webpackChunkName: "component---src-pages-oer-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */)
}

